import { IonContent, IonPage, useIonToast } from '@ionic/react';
import { FC } from 'react';
import clsx from 'clsx';
import { useHistory, useParams } from 'react-router-dom';
import { toastConfig } from 'config/ToastConfig';
import ValidationForm from './ValidationForm/ValidationForm';
import CopyRight from 'components/CopyRight';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxCommandCreator } from 'appRedux/actions';
import {  IState } from 'appRedux/createStore';
import { AUTH_STATE } from 'appRedux/types/reduxTypes';
import { CasinoReduxCommandCreator } from 'appRedux/actions/casinoCommandCreator';
import { AuthService } from 'services/auth';

interface ICasinoParams {
  casinoIDParam: string;
}

export const LinkPlayer: FC = () => {
  const [presentToast] = useIonToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);
  const casinoCommands = CasinoReduxCommandCreator(dispatch);
  const { casinoIDParam } = useParams<ICasinoParams>();
  const casinoID = parseInt(casinoIDParam) || 1;
  const auth = new AuthService();
  const userId = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.appUserPk
  ) as string;

  const handleErrorMessage = (message: string) => {
    toastConfig.color = 'danger';
    toastConfig.message = message;
    void presentToast(toastConfig);
  };

  const handleSuccessResponse = () => {
    toastConfig.color = 'success';
    toastConfig.message = 'Player Linked Successfully';
    void presentToast(toastConfig);
    commands.SetAuthState(AUTH_STATE.AUTHENTICATED);
    auth.GetLinkedAccounts(userId).subscribe({
      next(data) {
        if (data) {
          const linkedAccount = data.user.linkedAccounts;
          const casinoItem = linkedAccount?.find((item) => item.casinoId === casinoID);
          const isPlayerLinked = casinoItem ? true : false;
          const linkedCasinoId = casinoItem ? casinoItem?.casinoId : null;
          casinoCommands.SetLinkedCasinos({
            casinoLinkedAccounts: linkedAccount,
            isPlayerLinked,
            linkedCasinoId,
          });
        }
      },
      error(_err) {
        presentToast({
          position: 'top',
          duration: 5000,
          color: 'danger',
          message: 'Failed to retrieve linked accounts.',
        }).catch(() => null);
      },
    });
    history.replace('/tabs/home');
  };

  return (
    <IonPage className="app-parent">
      <IonContent className="scroll-disable">
        <div className={clsx('container')}>
          <div className="content">
            <ValidationForm onError={handleErrorMessage} onSuccess={handleSuccessResponse} />
          </div>
          <div className="footer">
            <CopyRight isShowVersion={false} />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
