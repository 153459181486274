import { useIonToast } from '@ionic/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

export const SubscriptionCancel = () => {
  const history = useHistory();
  const [presentToast] = useIonToast();

  useEffect(() => {
    void presentToast({
      position: 'top',
      duration: 3000,
      color: 'success',
      message: 'Subscription Cancelled Successfully!!',
    });
    history.push('/');
  }, []);
  return <div>NOW LOADING</div>;
};
