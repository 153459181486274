/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { take, tap } from 'rxjs';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { IState, ITabProperties, IUserInfo } from 'appRedux/createStore';
import NoPlayerIDLink, { SCREEN_TYPE } from 'components/NoPlayerIDLink/NoPlayerIDLink';
import SmartLoading from 'components/SmartLoading';
import { ICasinoReport } from 'appRedux/models/casinoModels';
import { CasinoService, IRequestAllReportParams } from 'services/casino';
import { getTimeFrameDetails } from 'config/config';
import { ICasinoLinkedAccount } from 'services/auth';
import { IUserFavorite } from 'appRedux/models/playerModels';

import {
  FilterTypeEnum,
  IFilterType,
  ListReportEnum,
  ListReportType,
  OrderByEnum,
} from '../../common/common';
import { getColorInsights } from '../../common/include';
import useFavorites from '../../common/reactHooks/useFavorites';
import { InsightValue } from '../../common/types';
import UserBadge from '../../components/UserBadge/UserBadge';

import HotListTable from './HotListTable';
import NoDetailsNotice from './NoDetailsNotice';
import './list-tab.scss';
import useSlotDetailHistory from 'globalServices/useSlotDetailHistory';
import { FilterReduxCommandCreator } from 'appRedux/actions/filterCommandCreator';

interface ListTabNewProps {
  reportType: ListReportType;
}

const ListTabNew: FC<ListTabNewProps> = ({ reportType }: ListTabNewProps) => {
  const casinoSvc = new CasinoService();
  const [presentToast] = useIonToast();
  const router = useIonRouter();
  const { addToSlotHistory } = useSlotDetailHistory();
  const ionInfiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);
  const dispatch = useDispatch();
  const filterCommandCreator = FilterReduxCommandCreator(dispatch);

  const isLandscape = useSelector<IState>(
    (state) => state.app.persistedState.orientationState?.isLandscape
  ) as boolean;
  const casinoId = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.kpCasinoPk
  ) as number;
  const userId = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.appUserPk
  ) as string;
  const casinoLinkedAccounts = useSelector<IState>(
    (state) => state.app.persistedState.linkPlayerState?.casinoLinkedAccounts
  ) as ICasinoLinkedAccount[];
  const casinoItem = casinoLinkedAccounts.find(
    (item: ICasinoLinkedAccount) => item.casinoId === casinoId
  );
  const isProUser = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.isProUser
  ) as boolean;
  const isPlayerLinked = useSelector<IState>(
    (state) => state.app.persistedState.linkPlayerState?.isPlayerLinked
  );
  const favoritesList = useSelector<IState>(
    (state) => state.app.persistedState.favorites.favoritesList
  ) as IUserFavorite[];

  const isAdvancedDisplayState = isProUser && isLandscape;
  // const { comparedSlots, iscomparedList } = slotsServiceState.context;
  // const isComparingSlots = iscomparedList[reportType as string];
  const { aliasInitial2, aliasName } = useSelector<IState>(
    (state) => state.app.persistedState.userInfo
  ) as IUserInfo;

  const tabFilters = useSelector<IState>(
    (state) => state.app.persistedState.filterState[reportType as IFilterType]
  ) as ITabProperties;

  const {
    timeframe: tabSelectedTimeframe,
    insight: tabSelectedInsight,
    advancedViewInsights,
  } = tabFilters;

  const [activeInsightFilter, setActiveInsightFilter] = useState<null | InsightValue>(
    tabSelectedInsight
  );
  const itemsPerPage = 50;
  const [hotDataList, setHotDataList] = useState<ICasinoReport[]>([]);
  const [totalReportCount, setTotalReportCount] = useState<number>(0);
  const [isEndReached, setIsEndReached] = useState<boolean>(false);
  const [isFavoriteLoading, setFavoriteLoading] = useState(false);

  const [casinoParams, setCasinoParams] = useState<IRequestAllReportParams>({
    appUserFk: userId,
    playerid: '',
    casinofk: casinoId,
    timeframe: 6,
    insight: 'spins',
    slot: 0,
    limit: itemsPerPage,
    pageNumber: 0,
    orderby: OrderByEnum.Asc,
    reportType: reportType,
  });

  const memoizedCasinoParams = useMemo(
    () => casinoParams,
    [
      casinoParams.appUserFk,
      casinoParams.playerid,
      casinoParams.casinofk,
      casinoParams.insight,
      casinoParams.timeframe,
      casinoParams.slot,
      casinoParams.limit,
      casinoParams.orderby,
      casinoParams.pageNumber,
      casinoParams.reportType,
    ]
  );

  const handleInputChange = (param: keyof IRequestAllReportParams, value: string | number) => {
    setCasinoParams((prevParams) => ({ ...prevParams, [param]: value }));
  };

  const handleFetchMore = (): void => {
    handleInputChange('pageNumber', casinoParams.pageNumber + 1);
  };

  const fetchListReport = (queryParams: IRequestAllReportParams) => {
    const { playerid, ...requestParams } = queryParams;
    const { appUserFk, slot, reportType, ...jkptParams } = queryParams;
    try {
      if (reportType === ListReportEnum.MyJackpot && isPlayerLinked) {
        casinoSvc.MyJackpotListReport(jkptParams).subscribe({
          next(casinoResponse) {
            if (casinoResponse) {
              if (casinoParams.pageNumber === 0) {
                setHotDataList(casinoResponse.DataSet);
              } else {
                setHotDataList((prevItems) => [...prevItems, ...casinoResponse.DataSet]);
              }
              if (casinoResponse.DataSet) {
                if (casinoResponse.DataSet.length < itemsPerPage) {
                  setIsEndReached(true);
                } else {
                  setIsEndReached(false);
                }
              }
            }
            ionInfiniteScrollRef.current?.complete();
          },
          error(_err) {
            presentToast({
              position: 'top',
              duration: 5000,
              color: 'danger',
              message: 'Failed to retrieve casino report.',
            }).catch(() => null);
            ionInfiniteScrollRef.current?.complete();
          },
          complete() {
            setFavoriteLoading(false);
          },
        });
      } else {
        casinoSvc.UsersListReport(requestParams).subscribe({
          next(casinoResponse) {
            if (casinoResponse) {
              if (casinoParams.pageNumber === 0) {
                setHotDataList(casinoResponse.DataSet);
              } else {
                setHotDataList((prevItems) => [...prevItems, ...casinoResponse.DataSet]);
              }
              if (casinoResponse.DataSet) {
                if (casinoResponse.DataSet.length < itemsPerPage) {
                  setIsEndReached(true);
                } else {
                  setIsEndReached(false);
                }
              }
            }
            ionInfiniteScrollRef.current?.complete();
          },
          error(_err) {
            presentToast({
              position: 'top',
              duration: 5000,
              color: 'danger',
              message: 'Failed to retrieve casino report.',
            }).catch(() => null);
            ionInfiniteScrollRef.current?.complete();
          },
          complete() {
            setFavoriteLoading(false);
          },
        });
      }
    } catch (error) {
      setFavoriteLoading(false);
    }
  };

  const fetchTotalReportCount = (queryParams: IRequestAllReportParams) => {
    const { playerid, pageNumber, limit, ...requestParams } = queryParams;
    const {
      appUserFk,
      slot,
      limit: limitValue,
      pageNumber: pageNumberValue,
      reportType,
      ...jkptParams
    } = queryParams;

    if (reportType === ListReportEnum.MyJackpot && isPlayerLinked) {
      casinoSvc
        .MyJackpotCountListReport(jkptParams)
        .pipe(
          take(1),
          tap((data) => {
            if (data) {
              if (data.DataSet) {
                setTotalReportCount(data.DataSet[0].total_count);
              }
            }
          })
        )
        .subscribe();
    } else {
      casinoSvc
        .CountUsersListReport(requestParams)
        .pipe(
          take(1),
          tap((data) => {
            if (data) {
              if (data.DataSet) {
                setTotalReportCount(data.DataSet[0].total_count);
              }
            }
          })
        )
        .subscribe();
    }
  };

  const { handleInsertFavorite, handleDeleteFavorite, fetchUserFavorites } = useFavorites({
    onOperationComplete: () => {
      setFavoriteLoading(false);
    },
  });

  const debouncedGetListReport = debounce((casinoParams) => {
    fetchListReport(casinoParams as IRequestAllReportParams);
  }, 500);

  const debouncedGetListReportCount = debounce((casinoParams) => {
    fetchTotalReportCount(casinoParams as IRequestAllReportParams);
  }, 500);

  useEffect(() => {
    if (casinoParams.appUserFk) {
      if (
        ((reportType === ListReportEnum.MyJackpot || reportType === ListReportEnum.MyPlay) &&
          isPlayerLinked) ||
        reportType === ListReportEnum.Favorites
      ) {
        if (casinoParams.pageNumber === 0) {
          setFavoriteLoading(true);
        }
        debouncedGetListReportCount(memoizedCasinoParams);
        debouncedGetListReport(memoizedCasinoParams);
      }

      return () => {
        debouncedGetListReportCount.cancel();
        debouncedGetListReport.cancel();
      };
    }
  }, [memoizedCasinoParams]);

  useEffect(() => {
    handleInputChange('insight', tabSelectedInsight as InsightValue);
    handleInputChange('orderby', OrderByEnum.Desc);
    handleInputChange('casinofk', casinoId);
    setActiveInsightFilter(tabSelectedInsight as InsightValue);
    handleInputChange('pageNumber', 0);
  }, [casinoId]);

  useEffect(() => {
    handleInputChange('insight', tabSelectedInsight as InsightValue);
    handleInputChange('orderby', OrderByEnum.Desc);
    setActiveInsightFilter(tabSelectedInsight as InsightValue);
    handleInputChange('pageNumber', 0);
  }, [tabSelectedInsight]);

  useEffect(() => {
    const filterTime = getTimeFrameDetails(tabSelectedTimeframe);
    handleInputChange('timeframe', filterTime?.id);
    handleInputChange('pageNumber', 0);
    handleInputChange('orderby', OrderByEnum.Desc);
  }, [tabSelectedTimeframe]);

  useEffect(() => {
    if (userId) {
      fetchUserFavorites(userId);
    }
  }, [userId]);

  useEffect(() => {
    handleInputChange('reportType', reportType);
  }, [reportType]);

  useEffect(() => {
    if (userId) {
      handleInputChange('appUserFk', userId);
    }
  }, [userId]);

  useEffect(() => {
    if (casinoItem) {
      handleInputChange('playerid', casinoItem.playerId);
    }
  }, [casinoItem]);

  const onFavorite = (slotDetail: ICasinoReport): void => {
    const isAlreadyFavorite = favoritesList.find(
      (favorite: IUserFavorite) => favorite.slotNumber == slotDetail.slot_number
    );
    setFavoriteLoading(true);
    if (!isAlreadyFavorite) {
      handleInsertFavorite(String(slotDetail.slot_number), userId);
    } else {
      handleDeleteFavorite(String(slotDetail.slot_number), userId);
    }
  };
  const handleChangeSlot = (selectedSlotId: number) => {
    addToSlotHistory(selectedSlotId);
    router.push('/detail');
  };

  const handleSortChange = (column: string) => {
    if (column != 'game_name') {
      const insightWasChanged = tabSelectedInsight !== column;
      if (insightWasChanged) {
        filterCommandCreator.SetFilterOptions(reportType as FilterTypeEnum, {
          insight: column as InsightValue,
        });
      }
    }
    setFavoriteLoading(true);
    setCasinoParams((prevParams) => ({
      ...prevParams,
      pageNumber: 0,
      insight: column,
      orderby: prevParams.orderby === OrderByEnum.Asc ? OrderByEnum.Desc : OrderByEnum.Asc,
    }));
  };

  const showTableHeader = userId && !isFavoriteLoading && hotDataList?.length > 0;
  const showMyPlayTabHeader = showTableHeader && reportType === ListReportEnum.MyPlay;
  const showFavoritesTabHeader = showTableHeader && reportType === ListReportEnum.Favorites;
  const showMyJackpotsTabHeader = showTableHeader && reportType === ListReportEnum.MyJackpot;
  const showHotListTable = !isFavoriteLoading && hotDataList && hotDataList?.length > 0;

  useEffect(() => {
    if (!isAdvancedDisplayState) {
      setActiveInsightFilter(tabSelectedInsight);
    }
  }, [isAdvancedDisplayState]);

  useEffect(() => {
    setActiveInsightFilter(tabFilters.insight);
  }, [tabFilters.insight]);

  useEffect(() => {
    setActiveInsightFilter(tabFilters.insight);
  }, []);

  const isShoPlayerCard =
    reportType === ListReportEnum.Standard || reportType === ListReportEnum.Favorites
      ? false
      : isPlayerLinked
      ? false
      : true;

  const screenType = reportType === ListReportEnum.MyPlay ? SCREEN_TYPE.MyPlay : SCREEN_TYPE.JKPTS;
  return (
    <IonContent>
      <SmartLoading loading={isFavoriteLoading} />

      {isShoPlayerCard && <NoPlayerIDLink screenType={screenType} />}

      {!isShoPlayerCard && (
        <div key={reportType} className="my-slot remain-height remain-height-list">
          {showMyPlayTabHeader && (
            <div className="heading6 table-title">Slots I&apos;ve Played in Last 7 Days</div>
          )}
          {showFavoritesTabHeader && (
            <div className="table-title">
              <div className="myslots-fav">
                <span>My Favorite Slots</span>
                <span className="like-btn like-active" />
              </div>
            </div>
          )}
          {showMyJackpotsTabHeader && (
            <div className="table-title">
              <div className="my-jackpot-user-badge-container">
                <UserBadge
                  isProAccount={isProUser}
                  aliasInitial={aliasInitial2!}
                  aliasName={aliasName!}
                />
              </div>
            </div>
          )}
          {!showHotListTable && !isFavoriteLoading && reportType === ListReportEnum.Standard && (
            <NoDetailsNotice selectedTimeFrameValue={tabSelectedTimeframe} />
          )}
          {!showHotListTable && !isFavoriteLoading && reportType === ListReportEnum.MyPlay && (
            <NoDetailsNotice selectedTimeFrameValue={tabSelectedTimeframe} />
          )}

          {!showHotListTable && !isFavoriteLoading && reportType === ListReportEnum.Favorites && (
            <NoDetailsNotice selectedTimeFrameValue={tabSelectedTimeframe} />
          )}

          {!showHotListTable && !isFavoriteLoading && reportType === ListReportEnum.MyJackpot && (
            <NoDetailsNotice selectedTimeFrameValue={tabSelectedTimeframe} />
          )}
          {showHotListTable && (
            <>
              <HotListTable
                listType={reportType}
                selectSlot={handleChangeSlot}
                datasets={hotDataList}
                // selectedSlots={comparedSlots[reportType]}
                floorDataSet={hotDataList}
                activeColColor={getColorInsights(tabSelectedInsight)}
                selectedInsight={tabSelectedInsight}
                selectedTimeFrame={tabSelectedTimeframe}
                isLoading={isFavoriteLoading}
                sortDirection={casinoParams.orderby}
                totalCount={totalReportCount.toString()}
                onClick={(rowData) => {
                  handleSortChange(rowData.insight);
                }}
                onFavorite={onFavorite}
                advancedViewInsights={advancedViewInsights}
                favoriteData={favoritesList}
                isFilterDataPresent={false}
                isExpandDateTime={false}
                activeInsightFilter={activeInsightFilter}
                isAdvancedDisplayState={isAdvancedDisplayState}
                tab={reportType}
              />
              <IonInfiniteScroll
                threshold="100px"
                ref={ionInfiniteScrollRef}
                disabled={isEndReached}
                onIonInfinite={handleFetchMore}
              >
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more data..."
                />
              </IonInfiniteScroll>
            </>
          )}
        </div>
      )}
    </IonContent>
  );
};

export default ListTabNew;
