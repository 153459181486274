import { FC, useState, useEffect } from 'react';
import './smart-loading.scss';
import SVGComponent from './SVGComponent';

interface SmartLoadingProps {
  loading?: boolean;
  customCls?: string;
}

export const SmartLoading: FC<SmartLoadingProps> = ({ loading: isLoading = false, customCls }) => {
  const [loadingState, setLoadingState] = useState('notLoading');

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (isLoading) {
      setLoadingState('shortLoading');
      timer = setTimeout(() => {
        setLoadingState('longLoading');
      }, 1300);
    } else {
      setLoadingState('notLoading');
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  if (loadingState === 'shortLoading' || loadingState === 'longLoading') {
    const message = loadingState === 'longLoading' ? 'Wait for it' : undefined;

    return <SVGComponent message={message} customCls={customCls} />;
  }

  return null;
};

export default SmartLoading;
