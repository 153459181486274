/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  faCalendarCheck,
  faDiamondTurnRight,
  faGlobe,
  faLocationPinLock,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import clsx from 'clsx';

import SmartLoading from 'components/SmartLoading';
import { ICasinoSchema } from 'appRedux/models/casinoModels';
import { CasinoService } from 'services/casino';
import { CasinoReduxCommandCreator } from 'appRedux/actions/casinoCommandCreator';
import { ReduxCommandCreator } from 'appRedux/actions';
import { AuthService, ICasinoLinkedAccount, IUserInfo } from 'services/auth';
import { FilterTypeEnum, checkProUser, heatLegendAllInsight } from 'common/common';
import { AUTH_STATE } from 'appRedux/types/reduxTypes';
import { PlayerService } from 'services/player';
import { FilterReduxCommandCreator } from 'appRedux/actions/filterCommandCreator';
import { IFilterState } from 'appRedux/createStore';
import { FloormapReduxCommandCreator } from 'appRedux/actions/floormapCommandCreator';

export const ChooseCasinos: FC = () => {
  const casinoSvc = new CasinoService();
  const auth = new AuthService();
  const playerSvc = new PlayerService();

  const router = useIonRouter();
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);
  const casinoCommands = CasinoReduxCommandCreator(dispatch);
  const floormapCommand = FloormapReduxCommandCreator(dispatch);

  const [presentToast] = useIonToast();

  const [isLoading, setIsLoading] = useState(false);
  const [casinos, setCasinos] = useState<ICasinoSchema[]>([]);
  const [comingSoonCasinos, setComingSoonCasinos] = useState<ICasinoSchema[]>([]);
  const filterCommandCreator = FilterReduxCommandCreator(dispatch);

  useEffect(() => {
    let isIgnore = false;
    if (!isIgnore) {
      setIsLoading(true);
      casinoSvc.GetAllCasinos().subscribe({
        next(casinoResponse) {
          if (casinoResponse) {
            const isActiveCasinos = casinoResponse.DataSet.filter(
              (casinoInfo) => casinoInfo.isactive && !casinoInfo.isComingSoon
            );
            const isComingSoonCasinos = casinoResponse.DataSet.filter(
              (casinoInfo) => casinoInfo.isactive && casinoInfo.isComingSoon
            );

            setCasinos(isActiveCasinos);
            setComingSoonCasinos(isComingSoonCasinos);
          }
        },
        error() {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to retrieve casino information.',
          }).catch(() => null);
        },
        complete() {
          setIsLoading(false);
        },
      });
    }

    return () => {
      isIgnore = true;
    };
  }, []);

  const updateUserPreference = (reqParam: IFilterState[] | undefined) => {
    if (reqParam === undefined) {
      filterCommandCreator.ResetAllFilters();
    } else {
      const filters = reqParam[0];

      if (filters && FilterTypeEnum && FilterTypeEnum.hottestSlots in filters) {
        filterCommandCreator.SetFilterOptions(FilterTypeEnum.hottestSlots, {
          ...filters[FilterTypeEnum.hottestSlots],
        });
      }

      if (filters && FilterTypeEnum && FilterTypeEnum.leaderboard in filters) {
        filterCommandCreator.SetFilterOptions(FilterTypeEnum.leaderboard, {
          ...filters[FilterTypeEnum.leaderboard],
        });
      }
      if (filters && FilterTypeEnum && FilterTypeEnum.jackpot in filters) {
        filterCommandCreator.SetFilterOptions(FilterTypeEnum.jackpot, {
          ...filters[FilterTypeEnum.jackpot],
        });
      }
      if (filters && FilterTypeEnum && FilterTypeEnum.myplay in filters) {
        filterCommandCreator.SetFilterOptions(FilterTypeEnum.myplay, {
          ...filters[FilterTypeEnum.myplay],
        });
      }
    }
  };

  const getUserAliasInformation = (casinoId: number) => {
    const playerSubscription = playerSvc.GetUserAlias(casinoId).subscribe({
      next(aliasResponse) {
        if (aliasResponse) {
          const { aliasInitial2, aliasInitial, aliasName } = aliasResponse.DataSet[0];

          const userAlias: Partial<IUserInfo> = {
            aliasInitial,
            aliasInitial2,
            aliasName,
          };

          commands.SetUserAlias(userAlias);
        }
      },
      error(err) {
        console.error(err);
      },
    });
    return () => {
      playerSubscription.unsubscribe();
    };
  };

  const onClickCasino = (casinoDetail: ICasinoSchema) => {
    if (casinoDetail.isComingSoon) {
      return;
    }

    setIsLoading(true);
    const { casinoTimezoneNew, casinoId } = casinoDetail;
    casinoCommands.SetCasinoSchema(casinoDetail);
    moment.tz.setDefault(casinoTimezoneNew);
    floormapCommand.SetDefaultHeatFilterAction(heatLegendAllInsight);
    auth.GetMe(casinoId).subscribe({
      next(data) {
        if (data?.user) {
          const { preference, linkedAccounts } = data.user;
          const { aliasInitial2, aliasInitial, aliasName, firstName, lastName, emailAddress } =
            data.user.info;

          const aliasInformation: Partial<IUserInfo> = {
            aliasInitial: aliasInitial,
            aliasInitial2: aliasInitial2,
            aliasName: aliasName,
          };

          getUserAliasInformation(casinoId);
          commands.SetTourState(data.user.info?.hasCompletedOnboarding as boolean);
          commands.SetUserPrivacy(data.user.info.isPublicVisible);
          commands.SetUserAlias({
            ...aliasInformation,
          });
          const userInformation: Partial<IUserInfo> = {
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
          };
          commands.SetUserInformation({
            ...userInformation,
          });

          if (data.user.subscriptions) {
            const subscription = data.user.subscriptions[0];

            if (subscription) {
              const { subscriptionData, customerData } = subscription;
              const customerId = customerData?.id;
              const planId = subscriptionData?.plan?.id;
              const subscriptionId = subscriptionData?.id;
              const currentPeriodEnd = subscriptionData?.current_period_end;
              const canceledAt = subscriptionData?.canceled_at;
              const subscriptionActive = subscriptionData?.status;
              const isProUser = checkProUser(subscriptionActive, currentPeriodEnd);

              if (isProUser) {
                preference && updateUserPreference(preference as IFilterState[]);
              } else {
                updateUserPreference(undefined);
              }

              commands.SetUserLevel({
                level: planId,
                customer_id: customerId,
                subscription_id: subscriptionId,
                current_period_end: currentPeriodEnd,
                canceled_at: canceledAt,
                isProUser,
                appUserPk: data.user.info?.appUserPk,
              });
            } else {
              updateUserPreference(undefined);
              commands.SetUserLevel({
                level: '',
                customer_id: '',
                subscription_id: '',
                isProUser: false,
              });
            }
          } else {
            commands.SetUserLevel({
              level: '',
              customer_id: '',
              subscription_id: '',
              isProUser: false,
            });
          }

          if (linkedAccounts && linkedAccounts.length > 0) {
            const casinoItem = linkedAccounts.find(
              (item: ICasinoLinkedAccount) => item.casinoId === casinoId
            );
            const isPlayerLinked = casinoItem ? true : false;
            if (isPlayerLinked) {
              const linkedCasinoId = casinoItem ? casinoItem?.casinoId : null;
              casinoCommands.SetLinkedCasinos({
                casinoLinkedAccounts: linkedAccounts,
                isPlayerLinked,
                linkedCasinoId,
              });
              router.push('/tabs/home');
            } else {
              router.push(`/auth/linkplayermodal/${casinoId}`);
            }
          } else {
            casinoCommands.SetLinkedCasinos({
              casinoLinkedAccounts: [],
              isPlayerLinked: false,
              linkedCasinoId: null,
            });
            router.push(`/auth/linkplayermodal/${casinoId}`);
          }
        }
      },
      error(err) {
        commands.SetUserInfo('', '');
        commands.SetAuthState(AUTH_STATE.SIGN_IN);
      },
      complete() {
        setIsLoading(false);
      },
    });
  };

  const renderCasinoItem = (casino: ICasinoSchema) => {
    const { displayName, casinoId, logos, contact, website, address } = casino;

    return (
      <div key={casinoId?.toString()}>
        <button
          className={clsx('my-casinos-box', casino?.isComingSoon ? 'my-casinos-box--light' : '')}
          onClick={onClickCasino.bind(this, casino)}
        >
          <div className="my-casinos-info my-casinos-info-section" key={address.displayAddress}>
            <div className="my-casinos-logo-icons">
              <div className="my-casino-logo-section">
                <img
                  src={logos.navBar}
                  className={clsx('casino-logo', casino?.isComingSoon ? 'casino-logo--light' : '')}
                />
              </div>
              <div className="my-casinos-link-icons">
                <FontAwesomeIcon
                  icon={faPhone}
                  id="callCasino"
                  data-casinoid={casinoId}
                  role="button"
                  onClick={(event) => onClickCasinoContact(event, contact)}
                />
                <FontAwesomeIcon
                  icon={faGlobe}
                  id="websiteCasino"
                  data-casinoid={casinoId}
                  role="button"
                  onClick={(event) => onClickCasinoContact(event, website)}
                />
                <FontAwesomeIcon
                  icon={faDiamondTurnRight}
                  id="directionsCasino"
                  data-casinoid={casinoId}
                  role="button"
                  onClick={(event) => onClickCasinoContact(event, address.mapsLink)}
                />
              </div>
            </div>
            <div className="my-casinos-name-number">{displayName}</div>
            <div className="my-casinos-description">{address.displayAddress}</div>
          </div>

          {casino.isComingSoon && (
            <div className="my-casinos-player-card-box">
              <div className="availability-date body primary-color-text">
                <FontAwesomeIcon className="icon" icon={faLocationPinLock} /> Available{' '}
                {casino?.isComingSoonDate || 'Soon'}
              </div>
            </div>
          )}
        </button>
      </div>
    );
  };

  const onClickCasinoContact = (event: React.MouseEvent<SVGElement>, url: string) => {
    event.stopPropagation();

    window.open(url, '_blank');
  };

  return (
    <IonPage className="app-parent">
      <IonContent>
        <SmartLoading loading={isLoading} />
        {!isLoading && (
          <div className="container">
            <div className="m-signup m-signup__choose-casino">
              <div className="m-signup__choose-casino-modal">
                <div className="modal-container">
                  <div className="m-signup__choose-casino-modal">
                    <div className="form-modal">
                      <div className="success-check" />

                      <div className="choose-casinos my-casinos">
                        <IonGrid className="ion-no-margin">
                          <IonRow>
                            <IonCol>
                              <div className="header-bar">
                                <span className="heading5 primary-color-text">Choose a Casino</span>
                              </div>
                            </IonCol>
                          </IonRow>
                          {(casinos || []).map((eachCasino) => {
                            return renderCasinoItem(eachCasino);
                          })}

                          {comingSoonCasinos.length >= 1 && (
                            <div className="upcoming-casinos">
                              <h2 className="header heading5 primary-color-text">
                                <FontAwesomeIcon icon={faCalendarCheck} /> Upcoming launches
                              </h2>

                              {comingSoonCasinos?.map((casino) => renderCasinoItem(casino))}
                            </div>
                          )}
                        </IonGrid>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-container">
                  <div className="logo">
                    <IonImg src="assets/images/SLOTcheckTM.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
