import { IonCard, IonCol, IonRow } from '@ionic/react';
import { CasinoReduxCommandCreator } from 'appRedux/actions/casinoCommandCreator';
import { SlotReduxCommandCreator } from 'appRedux/actions/slotCommandCreator';
import { ICasinoSearchData, IState } from 'appRedux/createStore';
import clsx from 'clsx';
import Search from 'components/Search';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Subscription } from 'rxjs';
import { CasinoService } from 'services/casino';

interface ISearchModalProps {
  searchBorder?: boolean;
  title: React.ReactNode;
  description?: React.ReactNode;
  closeHandler: () => void;
  onClickIndividualItem: (slotNumber: number) => void;
}

export const SearchModal = ({
  searchBorder,
  title,
  closeHandler,
  onClickIndividualItem,
}: ISearchModalProps) => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [clearSearchValue, setClearSearchValue] = useState(false);
  const dispatch = useDispatch();
  const casinoCommands = CasinoReduxCommandCreator(dispatch);
  const slotCommands = SlotReduxCommandCreator(dispatch);

  const casinoSvc = new CasinoService();
  const casinoId = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.kpCasinoPk
  ) as number;

  const casinoSearchData = useSelector<IState>(
    (state) => state.app.persistedState.casinoSearchData
  ) as ICasinoSearchData;

  const onItemClick = (slotNumbers: number[]): void => {
    slotCommands.ClearSelectedSlots();
    onClickIndividualItem(slotNumbers[0]);
    setClearSearchValue(true);
    closeHandler();
  };

  const fetchSearchData = useCallback(() => {
    setSearchLoading(true);
    let subscription: Subscription | null = null;
    if (casinoId && casinoId > 0) {
      if (!casinoSearchData || casinoSearchData.casinoId != casinoId) {
        subscription = casinoSvc.GetSearchData(casinoId).subscribe({
          next(casinoResponse) {
            if (casinoResponse) {
              casinoCommands.SetCasinoSearchData({ casinoId, data: casinoResponse.DataSet });
            }
            setSearchLoading(false);
          },
          error(_err) {
            setSearchLoading(false);
          },
          complete() {
            setSearchLoading(false);
          },
        });
      } else {
        setSearchLoading(false);
      }
    } else {
      setSearchLoading(false);
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [casinoId]);

  useEffect(() => {
    let isIgnore = false;
    if (!isIgnore) {
      fetchSearchData();
    }
    return () => {
      isIgnore = true;
    };
  }, []);

  return (
    <IonCard id="tour-modal" className="modal-container app-tour-tooltip search-modal">
      <h5>{title}</h5>
      <IonRow className="tour-modal-buttons">
        <IonCol>
          <Search
            shouldClearSearch={clearSearchValue}
            customCls={clsx(
              'machine-nav-search-input',
              searchBorder ? 'search-nav-border' : '',
              'active'
            )}
            searchData={casinoSearchData?.data}
            searchLoading={searchLoading}
            shouldFocus
            onClickItem={onItemClick}
            closeHandler={closeHandler}
            cssClass={'search-container__left'}
          />
        </IonCol>
      </IonRow>
    </IonCard>
  );
};
