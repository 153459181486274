import { ApiService } from '@classes/apiClasses';
import { IAliasResponse, IListResponse } from '@interfaces/services';
import { IUserFavorite } from 'appRedux/models/playerModels';
import { Get, Post } from 'lib/axiosWrapper';
import { IServiceReturn } from './common';

export interface IPlayerSubscriptionData {
  id: string;
  current_period_end: number;
  current_period_start: number;
  name: string;
  email: string;
  discount?: { coupon: { id: string; name: string; percent_off: number; amount_off: number } };
  status: string;
  plan?: Plan;
}

export interface Plan {
  product: string;
  id: string;
  interval: string;
  amount: number;
}

export interface LinkedAccount {
  casinoID: number;
  createDate: string | number;
  playerId: number;
  birthday: string;
}
export interface IUserDetails {
  _id: string;
  aliasName: string;
  aliasInitial: string;
  aliasInitial2: string;
  appUserPk: string;
  cognitoUserName: string;
  customerId: number;
  cellNumber: string;
  signUpDate: number;
  casinoId: number;
  linkedAccounts: LinkedAccount[];
}

export interface IPlayer extends IUserDetails, IPlayerSubscriptionData {
  subscriptionData: IPlayerSubscriptionData;
}

export interface IUserAliasV2 {
  appUserPk: string;
  aliasName: string;
  aliasInitial: string;
  aliasInitial2: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  isPublicVisible: boolean;
  cellNumber: string;
}

export interface IUserAlias {
  app_user_pk: string;
  player_bk: number;
  alias_name: string;
  alias_initial: string;
  alias_initial2: string;
  email_address: string;
  date_of_birth: string;
  first_name: string;
  last_name: string;
  address1: string;
  is_public_visible: boolean;
  cell_number: string;
  cell_number2: string;
  last_login: string;
  plan_type: number;
}

export interface IAliasInfo {
  alias_name: string;
  alias_initial: string;
  alias_initial2: string;
}

export interface IListPlayers {
  status: string;
  users: Array<IPlayer>;
  totalCount: number;
}

export interface ICountPlayers {
  status: string;
  count: number;
}

export interface ICheckoutResponse {
  id: string;
}

export interface IUserSubscriptionData {
  casinoId: number;
  customerId: string;
  phoneNumber: string;
  subscriptionData: IPlayerSubscriptionData;
}

interface PlayerInfo {
  casinoID: number;
  createDate: number;
  playerId: number;
  birthday: string;
}

interface ResultResponse {
  status: string;
  playerInfo: PlayerInfo;
}

interface LinkPlayerCardResponse {
  resultResponse?: ResultResponse;
  errorMsg?: string;
  data: {
    first_name: string;
  };
}

export interface IPlayerResponse {
  status: string;
  results: {
    first_name: string;
    last_name: string;
    alias_initial: string;
    alias_initial2: string;
    alias_name: string;
    cognitoUserName: string;
  };
}

export interface IRequestUpdateParam {
  cellNumber: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  aliasName?: string;
  aliasInitial?: string;
  aliasInitial2?: string;
}

interface SubscriptionDetails {
  current_period_end: number;
  current_period_start?: number;
  status: string;
}

export interface UserAnalytics {
  createDate: number;
  cognitoUserName: string;
  cellNumber: string;
  linkedAccounts: LinkedAccount[];
  subscriptionData: {
    casinoId?: number;
    subscriptionData: SubscriptionDetails;
  };
  lastActivity: number;
}

export class PlayerService extends ApiService {
  constructor() {
    super('player');
  }

  IsValidPlayerId(playerId: number) {
    return Get<ICheckoutResponse>(`${this.baseRoutePath}/IsValidPlayerId`, { playerId }, {}, true);
  }

  LinkPlayerCard(casinoId: number, appUserPk: string, playerId: string, birthDay: string) {
    return Get<LinkPlayerCardResponse>(
      `${this.baseRoutePath}/LinkPlayerCard`,
      { casinoId, appUserPk, playerId, birthDay },
      {},
      true
    );
  }

  GetPlayerInformation(phoneNumber: string) {
    return Get<IPlayerResponse>(
      `${this.baseRoutePath}/GetPlayerInformation`,
      { phoneNumber },
      {},
      true
    );
  }

  ListPlayers(
    pageNumber: number,
    limit: number,
    searchString: string,
    casinoId: number,
    sortField: string,
    sortOrder?: number
  ) {
    return Get<IListPlayers>(
      `${this.baseRoutePath}/ListPlayers`,
      { pageNumber, limit, searchString, casinoId, sortField, sortOrder },
      {},
      true
    );
  }

  GetCasinoSubscription(cognitoUserName: string, casinoId: number) {
    return Get<IUserSubscriptionData>(
      `${this.baseRoutePath}/GetCasinoSubscription`,
      { cognitoUserName, casinoId },
      {},
      true
    );
  }

  CountPlayers() {
    return Get<ICountPlayers>(`${this.baseRoutePath}/CountPlayers`, {}, {}, true);
  }

  UpdateEmailAddress(emailAddress: string, phoneNumber: string, appUserPk: string) {
    return Post<ICheckoutResponse>(
      `${this.baseRoutePath}/UpdateEmailAddress`,
      { emailAddress, phoneNumber, appUserPk },
      {},
      true
    );
  }
  GetUserFavorite(appUserPk: string) {
    return Get<IListResponse<IUserFavorite>>(
      `${this.baseRoutePath}/GetUserFavorite`,
      { appUserPk },
      {},
      true
    );
  }

  GetSlotUserFavorites(slotNumber: string) {
    return Get<IListResponse<IUserFavorite>>(
      `${this.baseRoutePath}/GetSlotUserFavorites`,
      { slotNumber },
      {},
      true
    );
  }

  GetPlayerAnalytics(casinoId: number) {
    return Get<IListResponse<UserAnalytics>>(
      `${this.baseRoutePath}/GetPlayerAnalytics`,
      { casinoId },
      {},
      true
    );
  }

  InsertFavorite(slotNumber: string, appUserPk: string) {
    return Post<IServiceReturn>(
      `${this.baseRoutePath}/InsertFavorite`,
      { slotNumber, appUserPk },
      {},
      true
    );
  }

  DeleteFavorite(slotNumber: string, appUserPk: string) {
    return Post<IServiceReturn>(
      `${this.baseRoutePath}/DeleteFavorite`,
      { slotNumber, appUserPk },
      {},
      true
    );
  }

  GetUserAlias(casinoId: number) {
    return Get<IListResponse<IUserAliasV2>>(
      `${this.baseRoutePath}/GetUserAlias`,
      { casinoId },
      {},
      true
    );
  }

  GenerateAlias() {
    return Get<IAliasResponse<IAliasInfo>>(`${this.baseRoutePath}/GenerateAlias`, {}, {}, true);
  }

  UpdatePrivacy(cellNumber: string, isPublicVisible: boolean) {
    return Post<ICheckoutResponse>(
      `${this.baseRoutePath}/UpdatePrivacy`,
      { cellNumber, isPublicVisible },
      {},
      true
    );
  }
  UpdateProfile(reqParams: IRequestUpdateParam) {
    return Post<ICheckoutResponse>(`${this.baseRoutePath}/UpdateProfile`, reqParams, {}, true);
  }
  UpdateLastLogin(cellNumber: string, lastLoginTimestamp: string) {
    return Post<ICheckoutResponse>(
      `${this.baseRoutePath}/UpdateLastLogin`,
      { cellNumber, lastLoginTimestamp },
      {},
      true
    );
  }
}
