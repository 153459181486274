/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxCommandCreator } from 'appRedux/actions';
import { StripeApp } from 'pages/Stripe';
import { LinkPlayer } from 'pages/Signup/views/LinkPlayer';
import { LinkPlayerModal } from 'pages/Signup/views/LinkPlayerModal';
import { UserConfirm } from 'pages/Signup/views/UserConfirm';
import { Verified } from 'pages/Signup/views/Verified';
import { VerifyAccount } from 'pages/Signup/views/VerifyAccount';
import { CreateAccount } from 'pages/Signup/views/CreateAccount';
import { NewUser } from 'pages/Signup/views/NewUser';
import { ForgotPassword } from 'pages/Signup/views/ForgotPassword';
import { ResetPassword } from 'pages/Signup/views/ResetPassword';
import { AdminDashboard } from 'pages/AdminDashboard';
import { ProtectedRoute, ProtectedRouteCasinoAdmin } from 'ProtectedRoute';
import TabRootPage from 'components/CasinoTabBar';
import UserProfile from 'pages/userProfile/UserProfile';
import EditProfile from 'pages/EditProfile/EditProfile';
import MyCasinos from 'pages/MyCasinos/MyCasinos';
import ChangePassword from 'pages/ChangePassword/ChangePassword';
import TermsPage from 'pages/TermsPage';
import Notifications from 'pages/Notifications';
import { CasinoAdminDashboard } from 'pages/CasinoAdminDashboard';
import { ChooseCasinos } from 'pages/Signup/views/ChooseCasinos';
import SideMenu from 'components/SideMenu/SideMenu';
import { SubscriptionCancel } from './components/subscriptionCancel';
import { ProcessSuccess } from './components/processSuccess';
import { getDeviceOrientation } from 'common/common';
import useWhiteLabelStyles, { WHITE_LABEL_TYPE_ENUM } from 'hooks/useWhiteLabelStyles';
import { debounce } from 'lodash';
import { Login } from 'pages/Signup/views/Login';
import Contact from 'pages/Contact/Contact';
import LegalDisclaimer from 'pages/Signup/views/LegalDisclaimer';
import LogoutPage from 'pages/Signup/views/Logout';
import { TestEveriLogin } from 'pages/TestEveriLogin';
import { AnalyticsDashboard } from 'pages/Analytics';
import { selectAuthStateTransformed } from 'appRedux/selectors';

export const SlotCheckRouter = () => {
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);
  const styles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.CANVAS);
  const authState = useSelector(selectAuthStateTransformed);
  const orientation = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const hasLargeScreen = window.screen.width >= 768;
    const orientation = getDeviceOrientation();
    let orientationType;
    if (hasLargeScreen && !isMobile) {
      orientationType = 'portrait';
    } else {
      orientationType = orientation || 'portrait';
    }

    const isLandscape = orientationType == 'landscape' ? true : false;

    return {
      isMobile,
      device: navigator.maxTouchPoints ? 'mobile' : 'computer',
      orientation: orientationType,
      isLandscape,
    };
  };

  const debouncedDetect = debounce(() => {
    const newOrientation = orientation();
    commands.SetOrientation(newOrientation);
  }, 200);

  useEffect(() => {
    const initialOrientation = orientation();
    commands.SetOrientation(initialOrientation);
    window.addEventListener('resize', debouncedDetect);
    debouncedDetect();
    return () => {
      debouncedDetect.cancel();
      window.removeEventListener('resize', debouncedDetect);
    };
  }, []);

  return (
    <IonApp className="root app-parent m-main" style={styles}>
      <IonReactRouter>
        <Switch>
          <IonSplitPane contentId="main" when="false" slot="end">
            <SideMenu />
            <IonRouterOutlet id="main">
              <Route
                exact
                path={['/canceled']}
                render={() => {
                  return <Redirect to="/auth/stripe" />;
                }}
              />
              <Route path="/logout" component={LogoutPage} />
              <Route exact path="/auth/stripe" component={StripeApp} />
              <Route path="/success/:sessionId" component={ProcessSuccess} />
              <Route path="/subscription_canceled" component={SubscriptionCancel} />
              {authState ? (
                <>
                  <Route exact path="/" render={() => <Redirect to="/tabs/home" />} />
                  <Route path="/auth/choosecasinos" component={ChooseCasinos} />
                  <Route path="/auth/linkplayermodal/:casinoIDParam" component={LinkPlayerModal} />
                  <Route path="/auth/linkplayer/:casinoIDParam" component={LinkPlayer} />
                  <Route path="/casinos" component={MyCasinos} />
                  <Route path="/profile" component={UserProfile} />
                  <Route path="/editprofile" component={EditProfile} />
                  <Route path="/changepassword" component={ChangePassword} />
                  <Route path="/contact-us" component={Contact} />
                  <Route path="/notifications" component={Notifications} />
                  <Route path="/tabs" component={TabRootPage} />
                  <Route path="/detail" component={TabRootPage} />
                  <Route path="/auth/legalDisclaimer" component={LegalDisclaimer} />
                  <Route path="/terms" component={TermsPage} />
                  <Route exact path="/auth/userconfirm" component={UserConfirm} />

                  <ProtectedRoute path="/admin/testEveriLogin" component={TestEveriLogin} />
                  <ProtectedRoute path="/admin/dashboard" component={AdminDashboard} />
                  <ProtectedRoute path="/admin/analytics" component={AnalyticsDashboard} />

                  <ProtectedRouteCasinoAdmin
                    path="/admin/casino"
                    component={CasinoAdminDashboard}
                  />
                </>
              ) : (
                <>
                  <Route path="*" render={() => <Redirect to="/" />} />
                  <Route exact path="/" component={Login} />
                  <Route exact path="/auth/login" component={Login} />
                  <Route exact path="/auth/forgotpassword" component={ForgotPassword} />
                  <Route exact path="/auth/resetpassword" component={ResetPassword} />
                  <Route exact path="/auth/createaccount" component={CreateAccount} />
                  <Route exact path="/auth/verifyaccount" component={VerifyAccount} />
                  <Route exact path="/auth/verified" component={Verified} />
                  <Route exact path="/auth/new" component={NewUser} />
                </>
              )}
            </IonRouterOutlet>
          </IonSplitPane>
        </Switch>
      </IonReactRouter>
    </IonApp>
  );
};
