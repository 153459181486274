import { IListResponse } from '@interfaces/services';
import { FloorPlan, SlotMetaData } from 'appRedux/createStore';
import { useState, useCallback, useEffect, useRef } from 'react';
import { CasinoService } from 'services/casino';

const useFetchGeoJson = (casinoId: number) => {
  const casinoSvc = new CasinoService();
  const [geoJsonData, setGeoJsonData] = useState<IListResponse<FloorPlan>>();
  const [slotMetaData, setSlotMetaData] = useState<IListResponse<SlotMetaData>>();
  const [getGeoJsonLoading, setGeoJsonLoading] = useState(false);
  const [slotMetaDataLoading, setSlotMetaDataLoading] = useState(false);
  const prevCasinoIdRef = useRef<number>();

  const fetchGeoJson = useCallback(() => {
    //Remove the condition "casinoId = 3" for RWC once the data is available.
    if (!casinoId || casinoId === 3) return;  
    setGeoJsonLoading(true);
    const subscription = casinoSvc.GetGeoJSON(casinoId).subscribe({
      next(casinoResponse) {
        if (casinoResponse) {
          setGeoJsonData(casinoResponse);
        }
        setGeoJsonLoading(false);
      },
      error(_err) {
        setGeoJsonLoading(false);
      },
      complete() {
        setGeoJsonLoading(false);
      },
    });

    return subscription;
  }, [casinoId]);

  const fetchMetaData = useCallback(() => {
    //Remove the condition "casinoId = 3" for RWC once the data is available.
    if (!casinoId || casinoId === 3) return;  
    setSlotMetaDataLoading(true);
    const subscription = casinoSvc.GetMapMetaData(casinoId).subscribe({
      next(metaDataResponse) {
        if (metaDataResponse) {
          setSlotMetaData(metaDataResponse);
        }
        setSlotMetaDataLoading(false);
      },
      error(_err) {
        setSlotMetaDataLoading(false);
      },
      complete() {
        setSlotMetaDataLoading(false);
      },
    });

    return subscription;
  }, [casinoId]);

  useEffect(() => {
    if (prevCasinoIdRef.current !== casinoId) {
      prevCasinoIdRef.current = casinoId;
      const geoJsonSubscription = fetchGeoJson();
      return () => {
        if (geoJsonSubscription) {
          geoJsonSubscription.unsubscribe();
        }
      };
    }
  }, [casinoId, fetchGeoJson]);

  useEffect(() => {
    if (prevCasinoIdRef.current !== casinoId) {
      prevCasinoIdRef.current = casinoId;
      const metaDataSubscription = fetchMetaData();
      return () => {
        if (metaDataSubscription) {
          metaDataSubscription.unsubscribe();
        }
      };
    }
  }, [fetchMetaData]);

  useEffect(() => {
    const metaDataSubscription = fetchMetaData();
    return () => {
      if (metaDataSubscription) {
        metaDataSubscription.unsubscribe();
      }
    };
  }, [fetchMetaData]);

  return { geoJsonData, getGeoJsonLoading, slotMetaData, slotMetaDataLoading };
};

export default useFetchGeoJson;
