import { IReducer } from './moduleReducerRegistry';
import { InsightValue, TimeFrameValue } from 'common/types';
import { config } from 'config/config';
import { IFilterState, ITabProperties } from 'appRedux/createStore';
import { FilterActionTypes } from 'appRedux/actions/filterCommandCreator';
import { IFilterType } from 'common/common';

const defaultFilters: ITabProperties = {
  timeframe: 'months1' as TimeFrameValue,
  insight: 'spins' as InsightValue,
  denominations: config.denom,
  advancedViewInsights: ['spins'] as InsightValue[],
};

const defaultValues: { [key in keyof IFilterState]: ITabProperties } = {
  leaderboard: { ...defaultFilters },
  jackpot: { ...defaultFilters, insight: 'jackpot_value' as InsightValue },
  myplay: { ...defaultFilters, insight: 'spins' as InsightValue },
  graphfilter: { ...defaultFilters, insight: 'spins' as InsightValue },
  mapfilter: { ...defaultFilters, insight: 'spins' as InsightValue },
  main: { ...defaultFilters, insight: 'spins' as InsightValue },
  hottestSlots: { ...defaultFilters, insight: 'spins' as InsightValue },
};

export const filterInitialState = {
  ...defaultValues,
};

export const filtersReducer: IReducer = (draft, action) => {
  switch (action.type) {
    case FilterActionTypes.SET_FILTER_OPTIONS:
      const { id: filterId, filters } = action.state;

      draft.app.persistedState.filterState[filterId as IFilterType] = {
        ...draft.app.persistedState.filterState[filterId as IFilterType],
        ...(filters as ITabProperties),
      };
      break;
    case FilterActionTypes.RESET_FILTERS:
      draft.app.persistedState.filterState = defaultValues;
      break;
  }
};
