import { ReactElement, useCallback, useEffect, useState } from 'react';
import { IonIcon } from '@ionic/react';
import { close, closeOutline, search } from 'ionicons/icons';
import { chain, map, noop } from 'lodash';
import useWhiteLabelStyles, { WHITE_LABEL_TYPE_ENUM } from 'hooks/useWhiteLabelStyles';
import { useRefWithClickOutsideListenerCallback } from '../../common/reactHooks';
import { titleCase } from '../../common/include';
import { ISearchInfo } from 'appRedux/models/casinoModels';
import SmartLoading from 'components/SmartLoading';
import { isTextFinite } from 'common/common';
import { InputAdornment, TextField } from '@material-ui/core';
import useSlotDetailHistory from 'globalServices/useSlotDetailHistory';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'appRedux/createStore';
import { SlotReduxCommandCreator } from 'appRedux/actions/slotCommandCreator';
import { selectSelectedSlots } from 'appRedux/selectors';

interface Slot {
  game_name: string;
  slot_number: string;
  denom: string;
}

interface SubGame {
  game_name: string;
  slot_number: string;
  slots: Slot[];
  denom: string;
  type: string;
}

interface GameInfo {
  game_name: string;
  slot_number: string;
  total: number;
  sub_game_names: SubGame[];
}

interface SearchProps {
  customCls?: string;
  shouldClearSearch?: boolean;
  shouldFocus?: boolean;
  cssClass?: string;
  closeHandler: () => void;
  onClickItem?: (slotNumbers: number[]) => void;
  searchData?: ISearchInfo[];
  searchLoading?: boolean;
}
export type ISearchKeyword = 'lookup' | 'theme' | 'style';
export type ISearchKeywordValues = ISearchKeyword[];

type ResultType = {
  [key: string]: any;
};

const Search = ({
  customCls = '',
  shouldClearSearch,
  cssClass,
  closeHandler,
  onClickItem = noop,
  searchData,
  searchLoading,
}: SearchProps): ReactElement<any, any> | null => {
  const { addToSlotHistory } = useSlotDetailHistory();
  const [gameName, setGameName] = useState<any>(null);
  const [gameDropDown, setGameDropDown] = useState<boolean>(false);
  const [gameArr, setGameArr] = useState<any>();
  const [filterArr, setFilterArr] = useState<any>([]);
  const dispatch = useDispatch();
  const slotCommands = SlotReduxCommandCreator(dispatch);
  const selectedSlots = useSelector(selectSelectedSlots);

  const btnstyles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.BUTTON);
  const [text, setText] = useState<string>('');
  const closeDropdown = useCallback(() => {
    setGameDropDown(false);
  }, []);
  const gameInputRefDiv = useRefWithClickOutsideListenerCallback(closeDropdown);

  useEffect(() => {
    clearSearch();
  }, []);

  useEffect(() => {
    if (shouldClearSearch) {
      setText('');
      setGameName(null);
    }
  }, [shouldClearSearch]);

  const processData = (field: string, originalData: ISearchInfo[]) => {
    return chain(originalData)
      .filter((object: ResultType) => object[field] != null && object[field] !== '')
      .groupBy(field)
      .map((groupedData, key) => {
        let gameData;
        const subGamedata = map(groupedData, (data) => {
          return {
            type: field,
            game_name: data.game_name.replaceAll('/', ' '),
            slot_number: Number(data.slot_number),
            denom: data.denom,
          };
        });

        if (field !== 'game_name') {
          const data = chain(subGamedata)
            .groupBy('game_name')
            .map((value: SubGame[], subKey: string) => {
              return {
                game_name: subKey.replaceAll('/', ' '),
                slots: value.map((slot: SubGame) => ({
                  ...slot,
                  slot_number: slot.slot_number,
                })),
                type: field,
                denom: value[0]?.denom,
              };
            })
            .value();
          gameData = data;
        } else {
          gameData = subGamedata;
        }

        return {
          game_name: key.replaceAll('/', ' '),
          total: subGamedata.length,
          sub_game_names: gameData,
        };
      })
      .value();
  };

  useEffect(() => {
    if (searchData) {
      const originalData = searchData;

      const resultGameName = processData('game_name', originalData);
      const resultManufacturer = processData('manufacturer', originalData);
      const resultTheme = processData('theme', originalData);
      const resultProgressiveDesc = processData('progressive_type_desc', originalData);
      const resultStyleDesc = processData('style_desc', originalData);
      const resultSystemType = processData('type', originalData);
      const resultSystemCommonName = processData('common_name', originalData);

      const formattedArr = [
        ...resultGameName,
        ...resultProgressiveDesc,
        ...resultTheme,
        ...resultManufacturer,
        ...resultStyleDesc,
        ...resultSystemType,
        ...resultSystemCommonName,
      ];

      resultSystemCommonName.sort((a, b) =>
        a.sub_game_names.length > b.sub_game_names.length ? -1 : 1
      );

      const formattedArrSort = formattedArr.sort((a, b) =>
        a.sub_game_names.length > b.sub_game_names.length ? -1 : 1
      );
      // resultGameName.forEach((a: any) => {
      //   if (slotGeneralInfo && !shouldClearSearch) {
      //     // This doesn't work for some reason with '===' even though these are both numbers not strings
      //     if (
      //       a.sub_game_names.some(
      //         (subElement: any) =>
      //           subElement.slot_number === parseInt(slotGeneralInfo?.[0]?.slot_number)
      //       )
      //     ) {
      //       // if (a?.sub_game_names?.includes(element => element.slot_numbers === slotGeneralInfo?.[0]?.slot_number)) {
      //       setGameName(a);
      //     }
      //   } else if (searchGameName) {
      //     // Same here, very strange considering that this is two strings
      //     if (searchGameName == a.game_name && a?.slot_numbers?.length) {
      //       setGameName(a);
      //     }
      //   }
      // });

      setFilterArr(formattedArrSort);
      setGameArr(formattedArrSort);
    }
  }, [searchData]);

  useEffect(() => {
    let isIgnore = false;
    if (!isIgnore && gameArr) {
      populateDropdown(text.toUpperCase());
    }
    return () => {
      isIgnore = true;
    };
  }, [text]);

  const populateDropdown = (filter: string) => {
    let matches = gameArr;

    if (filter) {
      const filterText = filter?.toLowerCase();
      if (isTextFinite(filterText)) {
        const filteredArray = filterArr.filter((item: GameInfo) => {
          return item.sub_game_names.some((subGame: SubGame) => subGame.type === 'game_name');
        });

        matches = filteredArray
          .map((item: GameInfo) => {
            const filteredSubGameNames = item.sub_game_names.filter(
              (subGame: SubGame) =>
                subGame.type === 'game_name' && subGame.slot_number.toString().includes(filterText)
            );
            if (filteredSubGameNames.length > 0) {
              return {
                ...item,
                sub_game_names: filteredSubGameNames,
              };
            } else {
              return null;
            }
          })
          .filter(Boolean);
      } else {
        matches = filterArr.filter((eachgame: { game_name: string }) => {
          if (eachgame.game_name?.toLowerCase().includes(filterText)) {
            return true;
          }
          return false;
        });
      }
    }
    // if (!filter && slotsHistory.length) {
    //   matches = slotsHistory;
    // }

    if (!filter) {
      matches = filterArr;
    }
    setGameArr(matches);
  };

  const onSelectGroup = (itemArr: any) => {
    const groupArr = [];
    if (!itemArr.slots) {
      for (let index = 0; index < itemArr.sub_game_names.length; index++) {
        const element = itemArr.sub_game_names[index];
        if (element?.slots) {
          for (let index2 = 0; index2 < element?.slots.length; index2++) {
            const element2 = element?.slots[index2];
            groupArr.push(element2.slot_number);
          }
        } else {
          groupArr.push(element.slot_number);
        }
      }
    } else {
      for (let index2 = 0; index2 < itemArr.slots.length; index2++) {
        const element2 = itemArr.slots[index2];
        groupArr.push(element2.slot_number);
      }
    }

    const slotsArr = [...groupArr];

    slotCommands.SelectedSlotsName(itemArr.game_name);
    slotCommands.SelectedSlots(slotsArr);
    closeHandler();
    // if (matches('tabs.main.slotMap')) {
    //   send({
    //     type: 'selectedToSlots',
    //     selectedSlots: slotsArr,
    //     selectedSlotsName: itemArr.game_name,
    //   });
    // } else if (state.matches({ tabs: { main: 'slotProList' } })) {
    //   send({
    //     type: 'goToHottestList',
    //     selectedSlots: slotsArr,
    //     selectedSlotsName: itemArr.game_name,
    //   });
    // } else {
    //   send({
    //     type: 'goToSelectedSlots',
    //     selectedSlots: slotsArr,
    //     selectedSlotsName: itemArr.game_name,
    //   });
    // }

    // send({
    //   type: 'setSearchGameName',
    //   searchGameName: itemArr.game_name,
    // });

    //setGameName(gameData);
    //onClickItem(slotsArr);
  };

  const onSelect = (slotnumber: number, gamename: string) => {
    setGameName(gamename);
    addToSlotHistory(slotnumber);
    onClickItem([slotnumber]);
  };

  // useEffect(() => {
  //   if (!slotGeneralInfo && !searchGameName && !selectedSlotsName) {
  //     setGameName(null);
  //   }
  //   if (selectedSlots?.length && selectedSlotsName) {
  //     setGameName({ game_name: selectedSlotsName });
  //   }

  //   if (searchGameName) {
  //     setGameName({ game_name: searchGameName });
  //   }
  // }, [slotGeneralInfo, searchGameName, selectedSlotsName]);

  const clearSearch = () => {
    if (selectedSlots?.length > 0) {
      // send('clearSelectedSlots');
    }
    setGameName(null);
    slotCommands.ClearSelectedSlots();
    // send('clearSearch');
  };

  const handleClearSearchInput = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setGameDropDown(false);
    clearSearch();
  };

  return (
    <>
      <SmartLoading loading={searchLoading} />
      {!searchLoading && (
        <div className={`m-slot-search ${customCls}`}>
          {!gameName ? (
            <div className="content-container">
              <TextField
                id="search-input"
                label="Search"
                variant="outlined"
                fullWidth
                value={text}
                onChange={(e) => setText(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IonIcon icon={search} className="search-box-section-icon" />
                    </InputAdornment>
                  ),
                }}
              />

              <div ref={gameInputRefDiv}>
                {gameArr && gameArr?.length > 0 && (
                  <div className={`slots-list-container ${cssClass}`} id="slot-list-container">
                    {gameArr &&
                      gameArr.map((eachgame: any, index: any) => (
                        <a key={index}>
                          <ul className="list-header" key={index}>
                            <li>
                              <div
                                id={'list-header' + index}
                                className={eachgame?.total ? '' : ''}
                                onClick={() => {
                                  if (eachgame?.slot_number) {
                                    onSelect(eachgame.slot_number, eachgame.game_name);
                                  } else {
                                    if (eachgame.sub_game_names.length === 1) {
                                      onSelect(
                                        eachgame.sub_game_names[0].slot_number,
                                        eachgame.game_name
                                      );
                                    } else {
                                      onSelectGroup(eachgame);
                                    }
                                  }
                                }}
                              >
                                <div className="game-name">
                                  {eachgame.slot_number && (
                                    <span className="slot-number">{eachgame.slot_number} </span>
                                  )}
                                  {eachgame.game_name && (
                                    <span className="slot-name">
                                      {titleCase(eachgame.game_name)}{' '}
                                    </span>
                                  )}
                                  {eachgame.total > 1 && ` (${eachgame.total})`}
                                  <span className="denom">{eachgame.denom}</span>
                                </div>
                              </div>
                              {eachgame?.sub_game_names && (
                                <ul className="list-style">
                                  {eachgame.sub_game_names.map((gamedesc: any, index2: any) => (
                                    <li
                                      key={index2}
                                      className="each-item"
                                      id={'search-by-number' + index2}
                                      onClick={() => {
                                        if (gamedesc.slots) {
                                          if (gamedesc.slots.length === 1) {
                                            onSelect(
                                              gamedesc.slots[0].slot_number,
                                              gamedesc.game_name
                                            );
                                          } else {
                                            onSelectGroup(gamedesc);
                                          }
                                        } else {
                                          onSelect(gamedesc.slot_number, gamedesc.game_name);
                                        }
                                      }}
                                    >
                                      <div className="game-name">
                                        {gamedesc.slot_number && (
                                          <span className="slot-number">
                                            {gamedesc.slot_number}{' '}
                                          </span>
                                        )}
                                        {gamedesc.game_name && (
                                          <span className="slot-name">
                                            {gamedesc.game_name.toLowerCase()}
                                          </span>
                                        )}
                                        {gamedesc?.slots && (
                                          <span className="slot-count">
                                            {' '}
                                            ({gamedesc?.slots.length})
                                          </span>
                                        )}
                                      </div>
                                      <span className="denom">{gamedesc.denom}</span>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          </ul>
                        </a>
                      ))}
                  </div>
                )}
              </div>
              {gameName && (
                <div onClick={clearSearch}>
                  <IonIcon icon={close} style={btnstyles} className="search-box-section-close" />
                </div>
              )}
            </div>
          ) : (
            <div className="search-box-section">
              <div className="search-box-section-text">
                {gameName?.game_name ? titleCase(gameName?.game_name) : titleCase(gameName)}
              </div>
              {gameName && (
                <div className="slot-close-icon" onClick={handleClearSearchInput}>
                  <IonIcon icon={closeOutline} className="" />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Search;
