import { ApiService } from '@classes/apiClasses';
import { ILeaderBoardList, IListResponse, ISlotResponse } from '@interfaces/services';
import { FloorPlan, SlotMetaData } from 'appRedux/createStore';
import {
  ICasinoSchema,
  ICasinoReport,
  ICasinoReportCount,
  ISearchInfo,
  ILeaderBoardReport,
  ILeaderBoardReportCount,
  IGraphGameData,
  ITrendsReport,
} from 'appRedux/models/casinoModels';
import { OrderByEnum, OrderValueEnum } from 'common/common';
import { Get } from 'lib/axiosWrapper';

export interface ICheckoutResponse {
  id: string;
}

export interface ICasinos {
  status: string;
  data: Array<ICasinoSchema>;
}

export interface IRequestPlayDataReport {
  casinofk: number;
  timeframe: number;
  insight: string;
  slot: number;
  orderby: OrderByEnum;
}

export interface IRequestCasinoParams {
  casinofk: number;
  timeframe: number;
  insight: string;
  slot: number;
  slotnumbers: string;
  limit: number;
  pageNumber: number;
  orderby: OrderByEnum;
}

export interface IRequestCommonParams {
  casinofk: number;
  timeframe: number;
  insight: string;
  orderby: OrderByEnum;
}

export interface IRequestLeaderCommonParams {
  casinofk: number;
  timeframe: number;
  insight: number;
  orderby: OrderByEnum;
  ordervalue: OrderValueEnum;
}

export interface IRequestPaginationParams {
  limit: number;
  pageNumber: number;
}

export interface IRequestUserParams extends IRequestCommonParams {
  appUserFk: string;
  slot: number;
  reportType: string;
}

export interface IRequestMyJackpotParams extends IRequestCommonParams {
  playerid: string;
}

export interface IRequestAllReportParams
  extends IRequestCommonParams,
    IRequestUserParams,
    IRequestMyJackpotParams,
    IRequestPaginationParams {}

interface IRequestWithoutPaginationParams extends IRequestUserParams {}
interface IRequestWithPaginationParams extends IRequestUserParams, IRequestPaginationParams {}

interface IRequestWithoutPaginationJackpotParams extends IRequestMyJackpotParams {}
interface IRequestWithPaginationJackpotParams
  extends IRequestMyJackpotParams,
    IRequestPaginationParams {}

export interface IRequestLeaderParam extends IRequestLeaderCommonParams {
  appUserFk: string;
}

export interface IRequestWithoutPaginationLeaderParams extends IRequestLeaderParam {}
export interface IRequestWithPaginationLeaderParams
  extends IRequestLeaderParam,
    IRequestPaginationParams {}

export interface IRequestDetailParam {
  casinofk: number;
  timeframe?: number;
  insight: string;
  slot: number;
  orderby: OrderByEnum;
}

export interface IRequestTrendsParam {
  casinoFk: number;
  insightFilter: string;
  slotNumber: number;
}
export interface IRequestGraphParam {
  casinofk: number;
  insight_filter: number;
  bucket_time: string;
  slot_number: number;
  timebucket_id: string;
  timeframe_filter: number;
}

export class CasinoService extends ApiService {
  constructor() {
    super('casino');
  }

  GetCasinoInfo(casinoId: string) {
    return Get<ICheckoutResponse>(`${this.baseRoutePath}/GetCasinoInfo`, { casinoId }, {}, true);
  }

  GetAllCasinos() {
    return Get<IListResponse<ICasinoSchema>>(`${this.baseRoutePath}/GetAllCasinos`, {}, {}, true);
  }

  ListReport(params: IRequestCasinoParams) {
    return Get<IListResponse<ICasinoReport>>(`${this.baseRoutePath}/ListReport`, params, {}, true);
  }

  GetPlayDataReport(params: IRequestPlayDataReport) {
    return Get<IListResponse<ICasinoReport>>(
      `${this.baseRoutePath}/GetPlayDataReport`,
      params,
      {},
      true
    );
  }

  GetIndividualSlotReport(params: IRequestDetailParam) {
    return Get<ISlotResponse<ICasinoReport>>(
      `${this.baseRoutePath}/GetIndividualSlotReport`,
      params,
      {},
      true
    );
  }

  GetGraphBucketSlotReport(params: IRequestDetailParam) {
    return Get<ISlotResponse<ICasinoReport>>(
      `${this.baseRoutePath}/GetGraphBucketSlotReport`,
      params,
      {},
      true
    );
  }

  GetTrendsData(params: IRequestTrendsParam) {
    return Get<IListResponse<ITrendsReport>>(
      `${this.baseRoutePath}/GetTrendsData`,
      params,
      {},
      true
    );
  }
  GetGraphData(params: IRequestGraphParam) {
    return Get<IListResponse<IGraphGameData>>(
      `${this.baseRoutePath}/GetGraphData`,
      params,
      {},
      true
    );
  }

  CountListReport(casinofk: number, timeframe: number, slot: number, slotnumbers: string) {
    return Get<IListResponse<ICasinoReportCount>>(
      `${this.baseRoutePath}/CountListReport`,
      { casinofk, timeframe, slot, slotnumbers },
      {},
      true
    );
  }

  UsersListReport(params: IRequestWithPaginationParams) {
    return Get<IListResponse<ICasinoReport>>(
      `${this.baseRoutePath}/UsersListReport`,
      params,
      {},
      true
    );
  }

  CountUsersListReport(params: IRequestWithoutPaginationParams) {
    return Get<IListResponse<ICasinoReportCount>>(
      `${this.baseRoutePath}/CountUsersListReport`,
      params,
      {},
      true
    );
  }

  MyJackpotListReport(params: IRequestWithPaginationJackpotParams) {
    return Get<IListResponse<ICasinoReport>>(
      `${this.baseRoutePath}/MyJackpotListReport`,
      params,
      {},
      true
    );
  }

  MyJackpotCountListReport(params: IRequestWithoutPaginationJackpotParams) {
    return Get<IListResponse<ICasinoReportCount>>(
      `${this.baseRoutePath}/MyJackpotCountListReport`,
      params,
      {},
      true
    );
  }

  LeaderBoardListReport(params: IRequestWithPaginationLeaderParams) {
    return Get<ILeaderBoardList<ILeaderBoardReport>>(
      `${this.baseRoutePath}/LeaderBoardListReport`,
      params,
      {},
      true
    );
  }

  LeaderBoardCountReport(params: IRequestWithoutPaginationLeaderParams) {
    return Get<ILeaderBoardList<ILeaderBoardReportCount>>(
      `${this.baseRoutePath}/LeaderBoardCountReport`,
      params,
      {},
      true
    );
  }

  GetGeoJSON(casinoId: number) {
    return Get<IListResponse<FloorPlan>>(
      `${this.baseRoutePath}/GetGeoJSON`,
      { casinoId },
      {},
      true
    );
  }

  GetMapMetaData(casinoId: number) {
    return Get<IListResponse<SlotMetaData>>(
      `${this.baseRoutePath}/GetMapMetaData`,
      { casinoId },
      {},
      true
    );
  }

  GetSearchData(casinoId: number) {
    return Get<IListResponse<ISearchInfo>>(
      `${this.baseRoutePath}/GetSearchData`,
      { casinoId },
      {},
      true
    );
  }
  TestProdCasino() {
    return Get<IListResponse<ISearchInfo>>(`${this.baseRoutePath}/TestProdCasino`, {}, {}, true);
  }
}
