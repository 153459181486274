import { IonButton, IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react';
import { IState } from 'appRedux/createStore';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

interface ICasinoParams {
  casinoIDParam: string;
}

export const LinkPlayerModal: FC = () => {
  const history = useHistory();
  const { casinoIDParam } = useParams<ICasinoParams>();
  const casinoID = parseInt(casinoIDParam) || 1;
  const routerLink = '/auth/linkplayer/' + casinoID.toString();

  const logo = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.logos.navBar
  ) as string;

  const notNowHandler = () => {
    history.replace('/tabs/home');
  };

  return (
    <IonPage className="app-parent">
      <IonContent className="scroll-disable">
        <div className="container">
          <div className="content">
            <div className="m-signup m-signup__link-player-modal">
              <div className="modal-container">
                <IonButton
                  fill="clear"
                  onClick={() => {
                    history.goBack();
                  }}
                  className="icon-back"
                  slot="start"
                >
                  <IonImg src="assets/images/back-icon.svg" />
                </IonButton>
                <div className="m-signup__link-player-modal">
                  <div className="form-modal">
                    <div className="success-check" />
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="header-bar">
                            <span className="heading6 primary-color-text">
                              Link Your Player ID(s)
                            </span>
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <div className="body-container">
                            <div className="my-casino-logo-section">
                              <IonImg src={logo} className="login-logo casino-logo" />
                            </div>

                            <span className="notice body-bold uppercase">Recommended</span>
                            <span className="body-bold primary-color-text">
                              Benefits of linking your player card:
                            </span>
                            <span className="body-bold primary-color-text benefits">
                              <ol>
                                <li>Track your play</li>
                                <li>Track your jackpots</li>
                                <li>See the leaderboard</li>
                              </ol>
                            </span>
                          </div>
                        </IonCol>
                      </IonRow>

                      <IonRow>
                        <IonCol>
                          <IonGrid>
                            <IonRow>
                              <IonCol>
                                <IonButton
                                  type="submit"
                                  className="submit-btn"
                                  routerLink={routerLink}
                                >
                                  Yes
                                </IonButton>
                              </IonCol>
                              <IonCol>
                                <IonButton className="cancel-btn" onClick={notNowHandler}>
                                  Not Now
                                </IonButton>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
              </div>
              <div className="footer-container">
                <div className="logo">
                  <IonImg src="assets/images/SLOTcheckTM.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
