/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol, useIonToast } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import FloorMap from '../FloorMapPackage/components/floorMap';
import './map-section.scss';
import PinSelectors from '../PinSelectors/PinSelectors';
import DropdownBar from '../DropdownBar/DropdownBar';
import { config } from '../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { IState, ITabProperties } from 'appRedux/createStore';
import { FilterTypeEnum, IFilterType, MapTypeEnum, OrderByEnum } from 'common/common';
import { CasinoService, IRequestCasinoParams, IRequestDetailParam } from 'services/casino';
import { ICasinoReport } from 'appRedux/models/casinoModels';
import { debounce } from 'lodash';
import useFetchGeoJson from 'common/reactHooks/useFetchGeoJson';
import { FloormapReduxCommandCreator } from 'appRedux/actions/floormapCommandCreator';
import { SectionID } from 'appRedux/reducers/slotReducer';
import { selectSelectedSlots } from 'appRedux/selectors';

interface MapSectionsProps {
  selectedSlotData: ICasinoReport;
  handleChangeSlot: (slot_number: number) => void;
  handleRearrangeComponent: () => void;
}

const MapSection: React.FC<MapSectionsProps> = ({
  selectedSlotData,
  handleChangeSlot,
  handleRearrangeComponent,
}) => {
  const casinoSvc = new CasinoService();
  const selectedSlots = useSelector(selectSelectedSlots);
  const graphFilters = useSelector<IState>(
    (state) => state.app.persistedState.filterState[FilterTypeEnum.mapfilter as IFilterType]
  ) as ITabProperties;

  const [collapse, setCollapse] = useState(true);
  const [isSlotPlayDataIsLoading, setSlotPlayDataIsLoading] = useState(false);
  const toggleCollapse = () => {
    setCollapse(!collapse);
  };
  const { timeframe, insight } = graphFilters;
  const casinoId = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema?.kpCasinoPk
  ) as number;
  const { geoJsonData, getGeoJsonLoading, slotMetaData } = useFetchGeoJson(casinoId);
  const dispatch = useDispatch();
  const floormapCommand = FloormapReduxCommandCreator(dispatch);
  const [playData, setPlayData] = useState<ICasinoReport[]>([]);
  const [presentToast] = useIonToast();
  const [casinoParams, setCasinoParams] = useState<IRequestDetailParam>({
    casinofk: casinoId,
    timeframe: 6,
    insight: 'spins',
    slot: 0,
    orderby: OrderByEnum.Desc,
  });

  const fetchMapData = (queryParams: IRequestCasinoParams) => {
    setSlotPlayDataIsLoading(true);
    try {
      const { ...restParam } = queryParams;
      casinoSvc.GetPlayDataReport(restParam).subscribe({
        next(casinoResponse) {
          if (casinoResponse) {
            setPlayData(casinoResponse.DataSet);
          }
        },
        error(_err) {
          presentToast({
            position: 'top',
            duration: 5000,
            color: 'danger',
            message: 'Failed to retrieve casino report.',
          }).catch(() => null);
        },
        complete() {
          setSlotPlayDataIsLoading(false);
        },
      });
    } catch (error) {}
  };

  const handleInputChange = (param: keyof IRequestCasinoParams, value: string | number) => {
    setCasinoParams((prevParams) => ({ ...prevParams, [param]: value }));
  };

  const debouncedGetSlotDataReport = debounce((casinoParams) => {
    fetchMapData(casinoParams);
  }, 500);

  useEffect(() => {
    debouncedGetSlotDataReport(casinoParams);
    return () => {
      debouncedGetSlotDataReport.cancel();
    };
  }, [casinoParams]);

  useEffect(() => {
    handleInputChange('insight', insight);
  }, [insight]);

  useEffect(() => {
    const filterTime = config.allTimeFrames.filter(
      (eachTimeframe) => eachTimeframe.value === timeframe
    )[0];
    handleInputChange('timeframe', filterTime.id);
  }, [timeframe]);

  return (
    <IonGrid className="map-section">
      <IonRow className="row-100">
        <IonCol size="12" className="map-section-col">
          <div className="section-header">
            <div className="section-header-icon section-header-icon-collapse">
              <FontAwesomeIcon
                icon={collapse ? faAngleDoubleUp : faAngleDoubleDown}
                onClick={toggleCollapse}
              />
            </div>
            <div className="section-header-title">
              <DropdownBar id={FilterTypeEnum.mapfilter} isShowShortName={true} />
            </div>
          </div>
        </IonCol>
        <IonCol size="12" className={collapse ? '' : 'content-hide'}>
          <div className="detail-view-floormap">
            <div className="map-info">
              Slot <span>#{selectedSlotData?.slot_number}</span> is located in bank{' '}
              <span>{selectedSlotData?.area + selectedSlotData?.section}</span>
            </div>
            <FloorMap
              mapId={MapTypeEnum.individualReportMap}
              slotData={playData}
              slotDataIsLoading={isSlotPlayDataIsLoading}
              key={selectedSlotData?.game_name + selectedSlotData?.slot_number}
              individualSlotNumber={selectedSlotData?.slot_number}
              selectedInsight={insight}
              selectedTimeFrame={timeframe}
              sortDirection={OrderByEnum.Desc}
              shouldHideSlotMark={false}
              isShowData={true}
              selectedSlots={selectedSlots}
              geoJsonData={geoJsonData}
              getGeoJsonLoading={getGeoJsonLoading}
              slotMetaData={slotMetaData}
              onClickHeatFilter={(selectedValue: number) => {
                floormapCommand.SetHeatFilterAction(selectedValue);
              }}
              onClickSlot={({ slot_number }) => handleChangeSlot(slot_number)}
            />
          </div>
        </IonCol>
        <IonCol size="12" className="ion-text-center ion-no-padding pin-section">
          <PinSelectors sectionId={SectionID.Map} onClickItem={handleRearrangeComponent} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MapSection;
