import lotcwallMapData from './lotc/lott-walls.json';
import lotclabelMapData from './lotc/lott-labels.json';
import lotcbankIdMapData from './lotc/lott-bank-ids.json';
import { addMapImage } from './lotc/mapboxImageLoad';

import eurekawallMapData from './eureka/eureka-walls.json';
import eurekalabelMapData from './eureka/eureka-labels.json';
import eurekabankIdMapData from './eureka/eureka-bank-ids.json';
import { addMapEurekaImage } from './eureka/mapboxImageLoad';

import rwcwallMapData from './rwc/rwc-walls.json';
import rwclabelMapData from './rwc/rwc-labels.json';
import rwcbankIdMapData from './rwc/rwc-bank-ids.json';
import rwcslotsMapData from './rwc/rwc-slots.json';
import { addMapRWCImage } from './rwc/mapboxImageLoad';

const useMapData = (casinoId: number) => {
  let wallMapData, labelMapData, bankIdMapData, slotsMapData;
  switch (casinoId) {
    case 1:
      wallMapData = lotcwallMapData;
      labelMapData = lotclabelMapData;
      bankIdMapData = lotcbankIdMapData;
      break;
    case 2:
      wallMapData = eurekawallMapData;
      labelMapData = eurekalabelMapData;
      bankIdMapData = eurekabankIdMapData;
      break;
    case 3:
      wallMapData = rwcwallMapData;
      labelMapData = rwclabelMapData;
      slotsMapData = rwcslotsMapData;
      bankIdMapData = rwcbankIdMapData;
      break;
    default:
      wallMapData = lotcwallMapData;
      labelMapData = lotclabelMapData;
      bankIdMapData = lotcbankIdMapData;
      labelMapData = lotclabelMapData;
      break;
  }
  return {
    wallMapData,
    labelMapData,
    bankIdMapData,
    addMapImage,
    addMapRWCImage,
    addMapEurekaImage,
    slotsMapData,
  };
};
export default useMapData;
