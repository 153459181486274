import { IPersistedState } from './reduxStore/createStore';
import merge from 'lodash/merge';

/**
 * Load state from local storage and merge it with the initial state or return
 * initial state
 * @param {IPersistedState} initialState - The initial state of the application
 * @returns {IPersistedState} - The merged state
 */
export const loadState = (initialState: IPersistedState) => {
  try {
    const serialState = window.localStorage.getItem('appState');

    if (!serialState) {
      return initialState;
    }

    return merge(initialState, JSON.parse(serialState)) as IPersistedState;
  } catch (err) {
    console.error('Error loading state from local storage:', err);
    return initialState;
  }
};

/**
 * Save state to local storage
 * @param {IPersistedState} persistedState - The state to be saved
 */
export const saveState = (persistedState: IPersistedState): void => {
  try {
    const serialState = JSON.stringify(persistedState);

    window.localStorage.setItem('appState', serialState);
  } catch (err) {
    console.error('Error saving state to local storage:', err);
  }
};

/**
 * Delete state from local storage
 */
export const deleteState = (): void => {
  try {
    window.localStorage.removeItem('appState');
  } catch (err) {
    console.error('Error deleting state from local storage:', err);
  }
};

export const clearState = (): void => {
  try {
    window.localStorage.clear();
  } catch (err) {
    console.error('Error deleting state from local storage:', err);
  }
};
