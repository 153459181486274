import { IonPage } from '@ionic/react';
import { IState } from 'appRedux/createStore';
import CasinoNavBar from 'components/CasinoNavBar';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InsightPopUp } from 'components/InsightsPopup/InsightsPopup';
import FooterLogo from 'components/FooterLogo';
import useWhiteLabelStyles, { WHITE_LABEL_TYPE_ENUM } from 'hooks/useWhiteLabelStyles';
import MapStats from './views/MapStats';
import AppTour from 'components/AppTour/AppTour';
import { OnboardingTourSteps } from 'components/AppTour/tours';
import { AuthService } from 'services/auth';
import { take, tap } from 'rxjs';
import { ReduxCommandCreator } from 'appRedux/actions';

export const Home = () => {
  const authService = new AuthService();
  const dispatch = useDispatch();

  const isLandscape = useSelector<IState>(
    (state) => state.app.persistedState.orientationState?.isLandscape
  ) as boolean;
  const hasCompletedOnboarding = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.hasCompletedOnboarding
  ) as boolean;

  const [activeInsight, setActiveInsight] = useState('');
  const [showIonModal, setShowIonModal] = useState(false);
  const styles = useWhiteLabelStyles(WHITE_LABEL_TYPE_ENUM.CANVAS);

  const commands = ReduxCommandCreator(dispatch);

  const onTourClose = () => {
    authService
      .UpdateMe({ hasCompletedOnboarding: true })
      .pipe(
        take(1),
        tap((res) => {
          if (res?.userInfo) {
            commands.SetTourState(true);
          }
        })
      )
      .subscribe();
  };

  return (
    <IonPage className="report-parent">
      {showIonModal && (
        <InsightPopUp
          isOpen={showIonModal}
          activeInsight={activeInsight}
          setShowIonModal={() => {
            setShowIonModal(false);
          }}
        />
      )}
      {!isLandscape && <CasinoNavBar hideNotify styles={styles} />}
      <div className="content">
        <div className="container" style={styles}>
          {/* {reportType === REPORT_TYPE_ENUM.HOT_SLOTS && ( //TODO: this needs to be converted to routes
            <HotSlots
              onClickInsight={(insight: string) => {
                showInsightModal(insight);
              }}
            />
          )}
          {reportType === REPORT_TYPE_ENUM.TYPES && (
            <Types
              onClickInsight={(insight: string) => {
                showInsightModal(insight);
              }}
            />
          )}
          {reportType === REPORT_TYPE_ENUM.ALL && (
            <AllSlots
              onClickInsight={(insight: string) => {
                showInsightModal(insight);
              }}
            />
          )}
          {reportType === REPORT_TYPE_ENUM.MAPS && (
            <> */}
          <AppTour
            steps={OnboardingTourSteps}
            onTourClose={onTourClose}
            showSkipButton
            hasCompletedOnboarding={hasCompletedOnboarding}
          />
          <MapStats />
          {/* </>
          )} */}
        </div>
        <FooterLogo />
      </div>
    </IonPage>
  );
};
