import {
  IonGrid,
  IonInput,
  IonImg,
  IonModal,
  IonDatetime,
  IonRow,
  IonCol,
  IonDatetimeButton,
  IonButton,
  useIonToast,
} from '@ionic/react';
import { FC, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { onPromise } from '../../CreateAccount';
import { format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxCommandCreator } from 'appRedux/actions';
import { IState } from 'appRedux/createStore';
import { AUTH_STATE } from 'appRedux/types/reduxTypes';
import { useHistory } from 'react-router';
import { PlayerService } from 'services/player';
import { ICasinoSchema } from 'appRedux/models/casinoModels';
import SmartLoading from 'components/SmartLoading';

interface FormData {
  playerId: string;
  birthday: string;
}

export const validate_account_schema = yup.object().shape({
  playerId: yup.string().required('Player ID is required'),
  birthday: yup
    .string()
    .nullable()
    .test('dateOfBirth', 'You must be 18 years or older', (value) => {
      const formattedDate = format(parseISO(value!), 'yyyy-MM-dd');
      return moment().diff(moment(formattedDate).format('YYYY-MM-DD'), 'years') >= 18;
    })
    .required('Birthday is required'),
});

interface ValidationFormProps {
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const ValidationForm: FC<ValidationFormProps> = ({ onError, onSuccess }) => {
  const playerSvc = new PlayerService();
  const selectedCasino = useSelector<IState>(
    (state) => state.app.persistedState.casinoSchema
  ) as ICasinoSchema;
  const [presentToast] = useIonToast();
  const [linkPlayerLoading, setLinkPlayerLoading] = useState(false);
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(validate_account_schema),
  });
  const userId = useSelector<IState>(
    (state) => state.app.persistedState.userInfo?.appUserPk
  ) as string;
  const dispatch = useDispatch();
  const commands = ReduxCommandCreator(dispatch);
  const history = useHistory();

  const linkLater = () => {
    commands.SetAuthState(AUTH_STATE.AUTHENTICATED);
    history.goBack();
  };

  const onSubmit = (data: FormData): void => {
    const formattedDate = format(parseISO(data.birthday), 'yyyy-MM-dd').replace(/-/g, '-');
    if (userId) {
      linkPlayerCard(selectedCasino.casinoId, userId, data.playerId, formattedDate);
    } else {
      presentToast({
        position: 'top',
        duration: 5000,
        color: 'danger',
        message: 'User ID not found. Please log in to your account to retry.',
      }).catch(() => null);
    }
  };

  const linkPlayerCard = (
    casinoId: number,
    appUserPk: string,
    playerId: string,
    birthday: string
  ) => {
    setLinkPlayerLoading(true);
    return playerSvc.LinkPlayerCard(casinoId, appUserPk, playerId, birthday).subscribe({
      next(data) {
        if (data?.resultResponse?.status === 'ok') {
          onSuccess();
        } else {
          const errorMessage = data?.errorMsg;
          console.error(errorMessage);
          errorMessage && void onError('Failed to link player card');
        }
      },
      error(err) {
        setLinkPlayerLoading(false);
        onError && onError('Something went wrong');
      },
      complete() {
        setLinkPlayerLoading(false);
      },
    });
  };

  return (
    <>
      <div className="m-signup m-signup__link-player">
        <SmartLoading loading={linkPlayerLoading} />
        <IonModal id="dobpicker" keepContentsMounted={true}>
          <Controller
            render={({ field: { onChange } }) => (
              <IonDatetime
                value={getValues('birthday')}
                id="dobDate"
                presentation="date"
                preferWheel={true}
                showDefaultButtons={true}
                onIonChange={(e) => onChange(e.detail.value)}
              ></IonDatetime>
            )}
            control={control}
            name="birthday"
            rules={{ required: true }}
          />
        </IonModal>
        <div className="modal-container">
          <form
            id="confirm-page"
            onSubmit={onPromise(handleSubmit((data: FormData) => void onSubmit(data)))}
            className="form-modal"
          >
            <IonGrid>
              <IonRow>
                <IonCol>
                  <div className="header-bar">
                    <div className="card-header">
                      <img src={selectedCasino.logos.playerCardImg} alt="Logo" className="logo" />
                      <span className="heading4 primary-color-text">
                        {selectedCasino.playerCardDisplayName}
                      </span>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <span className="button-text primary-color-text">Player ID</span>
                  <div className="input-group">
                    <Controller
                      name="playerId"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange } }) => (
                        <IonInput
                          type="text"
                          placeholder="Player card number"
                          value={getValues('playerId')}
                          onIonChange={onChange}
                        />
                      )}
                      rules={{ required: true }}
                    />
                    {errors.playerId && errors.playerId.message && (
                      <div className="field-validation-message">{errors.playerId.message}</div>
                    )}
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <div className="dob-picker">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="4">
                          <span className="button-text primary-color-text">Birthday</span>
                        </IonCol>
                        <IonCol size="8">
                          <div className="button-container">
                            <IonDatetimeButton datetime="dobDate">Dob</IonDatetimeButton>
                          </div>
                        </IonCol>

                        {errors.birthday && errors.birthday.message && (
                          <div className="field-validation-message">{errors.birthday.message}</div>
                        )}
                      </IonRow>
                    </IonGrid>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton type="submit" className="submit-btn">
                    Link
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>

            <div className="create-account-link">
              <>
                <span className="unregister" data-cy="login-button-mobile-reg" onClick={linkLater}>
                  Maybe Later
                </span>
              </>
            </div>
          </form>
        </div>

        <div className="footer-container">
          <div className="logo">
            <IonImg src="assets/images/SLOTcheckTM.svg" />
          </div>
        </div>
      </div>
    </>
  );
};
export default ValidationForm;
